import { lazy } from 'react'
import { routeProps } from '../interface/globalTypes'
import { HOME_PAGE_HEADING, HOME_PAGE_SUBHEADING } from '../utils/CONSTANTS'

const AllSuppliers = lazy(() => import('../components/pages/AllSuppliers'))
const AllParagraphs = lazy(() => import('../components/pages/AllParagraphs'))
const Workflow = lazy(() => import('../components/pages/Workflow/Workflow'))
const Welcome = lazy(() => import('../components/pages/Home'))

const MyESOW = lazy(() => import('../components/pages/MyESOW/MyESOW'))
const BasicSearch = lazy(() => import('../components/pages/BasicSearch/BasicSearch'))
const ManageNews = lazy(() => import('../components/pages/ManageNews'))
const ManageDropdownOptions = lazy(
    () => import('../components/pages/ManageDropdownOptions/DropdownOptions')
)
const QuickSearch = lazy(() => import('components/pages/QuickSearch/QuickSearch'))
const Messages = lazy(() => import('../components/pages/Messages/Messages'))

// const Help = lazy(() => import('../components/pages/Help/Help'))
const ViewAcknowledgements = lazy(() => import('../components/pages/ViewAcknowledgements'))
const Support = lazy(() => import('../components/pages/Help/Support'))
const emailPreferences = lazy(() => import('../components/pages/EmailPreferences'))

const routes: routeProps[] = [
    {
        path: '/home',
        icon: '',
        text: 'Home',
        key: 'home',
        mainNav: true,
        headerNav: false,
        Component: Welcome,
        pageTitle: HOME_PAGE_HEADING,
        pageSubTitle: HOME_PAGE_SUBHEADING,
        navPlacement: 'home'
    },
    {
        path: '/MyESOW',
        icon: '',
        text: 'My ESOW',
        key: 'myESOW',
        mainNav: true,
        headerNav: false,
        Component: MyESOW,
        pageSubTitle: 'My ESOW List',
        navPlacement: 'myESOW'
    },
    {
        path: '/AllMessages',
        icon: '',
        text: 'All Messages',
        key: 'allMessages',
        mainNav: true,
        headerNav: false,
        Component: Messages,
        pageSubTitle: 'All ESOW Messages List',
        navPlacement: 'allMessages'
    },
    {
        path: '/Search',
        icon: '',
        text: 'Search',
        key: 'basicSearch',
        mainNav: true,
        headerNav: false,
        Component: BasicSearch,
        pageSubTitle: 'ESOW List',
        navPlacement: 'myESOW'
    },
    {
        path: 'https://intranet.volvocars.net/sites/esowportal/esowso/SitePages/Home.aspx',
        icon: '',
        text: 'ESOW Guidelines',
        key: 'esowguidelines',
        mainNav: true,
        headerNav: false,
        Component: Support,
        pageSubTitle: 'ESOW Guidelines',
        navPlacement: 'help'
    },
    {
        path: 'https://volvocars.percipio.com/linked-contents/74454c50-f1ee-47fd-980a-3e830cc32c4f/landing',
        icon: '',
        text: 'SCOT E-learning',
        key: 'scotelearning',
        mainNav: true,
        headerNav: false,
        Component: Support,
        pageSubTitle: 'SCOT E-learning',
        navPlacement: 'help'
    },
    {
        path: '/Support',
        icon: '',
        text: 'SCOT Support',
        key: 'scotsupport',
        mainNav: true,
        headerNav: false,
        Component: Support,
        pageSubTitle: 'SCOT Support',
        navPlacement: 'help'
    },
    {
        path: '<About>',
        icon: '',
        text: 'About',
        key: 'about',
        mainNav: true,
        headerNav: false,
        Component: Support,
        pageSubTitle: 'About',
        navPlacement: 'help'
    },
    {
        path: '/dashboard/quickSearch',
        icon: '',
        text: 'Search',
        key: 'quickSearch',
        mainNav: false,
        headerNav: false,
        Component: QuickSearch,
        pageSubTitle: 'ESOW List',
        navPlacement: 'myESOW'
    },
    {
        path: '/managenews',
        icon: '',
        text: 'Manage News',
        key: 'ManageNews',
        mainNav: false,
        headerNav: true,
        Component: ManageNews,
        pageSubTitle: 'Manage News',
        navPlacement: 'settings'
    },

    {
        path: '/allparagraphs',
        icon: '',
        text: 'All Paragraphs',
        key: 'AllParagraphs',
        mainNav: false,
        headerNav: true,
        Component: AllParagraphs,
        pageSubTitle: 'all paragraphs',
        navPlacement: 'settings'
    },
    {
        path: '/allsuppliers',
        icon: '',
        text: 'All Suppliers',
        key: 'AllSuppliers',
        mainNav: false,
        headerNav: true,
        Component: AllSuppliers,
        pageSubTitle: 'all suppliers',
        navPlacement: 'settings'
    },
    {
        path: '/workflow/:documentId',
        icon: '',
        text: 'ESOW Workflow',
        key: 'esowworkflow',
        mainNav: false,
        headerNav: false,
        Component: Workflow,
        pageTitle: 'ESOW Workflow',
        pageSubTitle: HOME_PAGE_SUBHEADING
    },
    {
        path: '/workflow/:type/:commodityname/:documentId',
        icon: '',
        text: 'ESOW Workflow',
        key: 'esowworkflow',
        mainNav: false,
        headerNav: false,
        Component: Workflow,
        pageTitle: 'ESOW Workflow',
        pageSubTitle: HOME_PAGE_SUBHEADING
    },
    {
        path: '/viewAcknowledgements',
        icon: '',
        text: 'View Acknowledgements',
        key: 'ViewAcknowledgements',
        mainNav: false,
        headerNav: true,
        Component: ViewAcknowledgements,
        pageSubTitle: 'View Acknowledgements',
        navPlacement: 'settings'
    },
    {
        path: '/managedropdownoptions',
        icon: '',
        text: 'Manage Dropdown Options',
        key: 'ManageDropdownOptions',
        mainNav: false,
        headerNav: true,
        Component: ManageDropdownOptions,
        pageSubTitle: 'Manage Dropdown Options',
        navPlacement: 'settings'
    },
    {
        path: '/EmailPreferences',
        icon: '',
        text: 'Email Preferences',
        key: 'EmailPreferences',
        mainNav: false,
        headerNav: false,
        Component: emailPreferences,
        pageSubTitle: 'ESOW List',
        navPlacement: 'myESOW'
    }
]

export default routes
