import React from 'react'
import release from '../../../CHANGELOG.json'

const ChangeLog: React.FC<any> = () => {
    return (
        <table cellPadding={10} cellSpacing={10}>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Version</th>
                </tr>
            </thead>
            <tbody>
                {release?.changelog.map((row, index) => (
                    <tr key={index}>
                        <td>{row.date}</td>
                        <td>{row.title}</td>
                        <td>{row.description}</td>
                        <td>{row.version}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ChangeLog
