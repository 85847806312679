import * as React from 'react'
import {
    ContextualMenu,
    DirectionalHint,
    IContextualMenuItem
} from '@fluentui/react/lib/ContextualMenu'
import { Stack } from '@fluentui/react'
import ComdBarButton from '../CommandBarButton'

interface DropdownNavProps {
    menuItems: IContextualMenuItem[]
    iconName?: string
    menuText?: string
}

const navCursorPointerCss: React.CSSProperties = { cursor: 'pointer' }
const paddingCss: React.CSSProperties = { paddingBottom: '4px' }
const paddingDrpDownCss: React.CSSProperties = { padding: '8px 0' }
const DropdownNavComponent: React.FunctionComponent<DropdownNavProps> = ({
    menuItems,
    iconName = '',
    menuText = ''
}) => {
    const linkRef = React.useRef(null)
    const [showContextualMenu, setShowContextualMenu] = React.useState(false)
    const onShowContextualMenu = React.useCallback(
        (ev: React.MouseEvent<HTMLElement>) => {
            ev.preventDefault()
            setShowContextualMenu(!showContextualMenu)
        },
        [showContextualMenu]
    )
    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), [])

    return (
        <>
            <Stack>
                <div ref={linkRef} onClick={onShowContextualMenu} style={navCursorPointerCss}>
                    {iconName === '' ? (
                        <div style={paddingCss}>
                            <ComdBarButton label={menuText} className='navBarItems' />
                        </div>
                    ) : menuText === '' ? (
                        <ComdBarButton
                            iconProps={{
                                iconName: iconName,
                                styles: {
                                    root: { color: 'black' }
                                }
                            }}
                            className='navBarItems'
                        />
                    ) : (
                        <div style={paddingDrpDownCss}>
                            <ComdBarButton
                                label={menuText}
                                iconProps={{
                                    iconName: iconName,
                                    styles: {
                                        root: { color: 'black' }
                                    }
                                }}
                                className='navBarItems'
                            />
                        </div>
                    )}
                </div>
            </Stack>
            <ContextualMenu
                items={menuItems}
                directionalHint={DirectionalHint.bottomLeftEdge}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </>
    )
}

export default DropdownNavComponent
