import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import routes from '../../../routes'
import Header from './HeaderComponent'
import { MessageBarType, Stack, StackItem } from '@fluentui/react'
import { PageBannerTop } from '../../molecules/PageBannerTop/PageBannerTop'
import { useMsal, useAccount } from '@azure/msal-react'
import UnAuthorized from '../AuthFailure/UnAuthorized'
import { LOG_MESSAGES } from '../../../utils/ERRORMESSAGES'
import { NotificationContext } from '../../../context/NotificationContext'
import { NotificationProps } from '../../../interface/globalTypes'
import NotificationAlert from '../../atoms/Notification/NotificationComponent'
import { RequestAccessToken } from 'services/AuthUtils'
import LoadingComponent from '../../atoms/LoadingComponent'
import { ChatAllMsgTabParagNameContext } from 'context/ChatsContext'

const Layout: React.FC<{}> = () => {
    const [loader, setLoader] = useState(false)
    const { instance, accounts, inProgress } = useMsal()
    const [notificationAlert, setNotificationAlert] = useState<NotificationProps>({
        message: '',
        type: MessageBarType.info
    })
    const account = useAccount(accounts[0])
    const roles = account?.idTokenClaims?.roles

    const [currentAllChatMsgTabName, setCurrentAllChatMsgTabName] = useState('')
    const [currentAllChatMsgParagName, setCurrentAllChatMsgParagName] = useState('')

    function setLoading(loading: boolean) {
        setLoader(loading)
    }
    function setNotifications(message: string, type: MessageBarType.error) {
        setNotificationAlert({
            message: message,
            type: type
        })
    }

    const allChatMsgTabParagNameContextValue = {
        currentAllChatMsgParagName: currentAllChatMsgParagName,
        updateCurrentAllChatMsgParagName: setCurrentAllChatMsgParagName,
        currentAllChatMsgTabName: currentAllChatMsgTabName,
        updateCurrentAllChatMsgTabName: setCurrentAllChatMsgTabName
    }

    useEffect(() => {
        if (accounts) {
            RequestAccessToken(instance)
        }
    }, [instance, accounts, inProgress])

    return (
        <>
            {roles && roles?.length > 0 ? (
                <Router>
                    <Header />
                    <Stack horizontal>
                        <StackItem className='pageContentArea'>
                            <NotificationContext.Provider
                                value={{
                                    notificationAlert,
                                    setNotifications,
                                    loading: loader,
                                    setLoading
                                }}
                            >
                                <ChatAllMsgTabParagNameContext.Provider
                                    value={allChatMsgTabParagNameContextValue}
                                >
                                    <Routes>
                                        <Route path='/' element={<Navigate replace to='/home' />} />
                                        {routes.map(
                                            ({
                                                path,
                                                Component,
                                                text,
                                                pageTitle,
                                                pageSubTitle
                                            }) => (
                                                <Route
                                                    path={path}
                                                    key={path}
                                                    element={
                                                        <>
                                                            <PageBannerTop
                                                                heading={pageTitle || text}
                                                                subHeading={pageSubTitle}
                                                            />
                                                            <div className='layout-main-component'>
                                                                <div className='componentContainer'>
                                                                    <Suspense
                                                                        fallback={
                                                                            <LoadingComponent />
                                                                        }
                                                                    >
                                                                        <div className='layoutContainer'>
                                                                            <NotificationAlert />
                                                                            {loader && (
                                                                                <LoadingComponent />
                                                                            )}
                                                                            <Component />
                                                                        </div>
                                                                    </Suspense>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                ></Route>
                                            )
                                        )}
                                        <Route
                                            path='*'
                                            element={<Navigate replace={true} to='/' />}
                                        />
                                    </Routes>
                                </ChatAllMsgTabParagNameContext.Provider>
                            </NotificationContext.Provider>
                        </StackItem>
                    </Stack>
                </Router>
            ) : (
                <UnAuthorized
                    status={LOG_MESSAGES.FORBIDDEN_STATUS}
                    message={LOG_MESSAGES.FORBIDDEN_STATUS_MESSAGE}
                />
            )}
        </>
    )
}

export default Layout
