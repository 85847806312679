import { createContext } from 'react'

export interface IChatsContext {
    currentChatParagName: any
    updateCurrentChatParagName: (data: any) => void
}

export const ChatsContext = createContext<IChatsContext>({} as IChatsContext)

export interface IChatAllMsgTabParagNameContext {
    currentAllChatMsgTabName: any
    updateCurrentAllChatMsgTabName: (data: any) => void
    currentAllChatMsgParagName: any
    updateCurrentAllChatMsgParagName: (data: any) => void
}

export const ChatAllMsgTabParagNameContext = createContext<IChatAllMsgTabParagNameContext>(
    {} as IChatAllMsgTabParagNameContext
)
