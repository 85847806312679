import React from 'react'
import './_loadingSpinner.scss'

function LoadingSpinner() {
    return (
        <section className='loading_spinner'>
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export default LoadingSpinner
