import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { IContextualMenuItem, Stack, Icon, StackItem, Separator } from '@fluentui/react'
import routes from '../../../routes'
import DropdownNavComponent from '../../atoms/DropdownNav'
import ButtonComponent from '../../atoms/Button'
import logo from '../../../assets/volvo-logo2.svg'
import { Navbar } from '../../atoms/Navbar/NavbarComponent'
import { useAccount, useMsal } from '@azure/msal-react'
import SCOTLogo from '../../../assets/SCOTlogo.png'
import { ROLES } from '../../../utils/CONSTANTS'
import { Login, MgtTemplateProps } from '@microsoft/mgt-react'
import { BASE_URL, PING_PONG } from 'utils/ENDPOINTS'
import { ApiService } from 'services/apiUtils'
import { runValidation } from 'utils/VALIDATIONS'

const styles = {
    root: {
        background: 'black',
        padding: '0px 1px'
    }
}

const itemStylesLeft: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'start',
    flex: 35
}

const itemStylesMiddle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    flex: 30
}

const itemStylesRight: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'end',
    flex: 35
}

const Header: React.FC<{}> = () => {
    const { accounts } = useMsal()
    const [searchVal, setsearchVal]: any = useState<string>('')
    const [errorMessage, seterrorMessage] = useState<string>('')
    const navigate = useNavigate()
    const account: any = useAccount(accounts[0])
    const roles = account ? account?.idTokenClaims?.roles : []
    const [settingsMenuItems, setsettingsMenuItems]: any = useState([])

    const headerNavRoutes = routes.filter((item) => item?.headerNav)
    const updateNavHead = useCallback(() => {
        const settingsnavRoutes: IContextualMenuItem[] = []
        headerNavRoutes.forEach((item) => {
            const newMenuItem: any = {
                key: item.path,
                text: item.text,
                onClick: () => navigate(`${item.path}`)
            }
            settingsnavRoutes.push(newMenuItem)
        })
        setsettingsMenuItems(settingsnavRoutes)
        return settingsnavRoutes
    }, [])

    useEffect(() => {
        updateNavHead()
    }, [])

    const TemplateVCCLoggedIn = (props: MgtTemplateProps) => {
        const person = props.dataContext.personDetails

        useEffect(() => {
            setInterval(async () => {
                const pathName = `${BASE_URL}${PING_PONG}`

                const requestObj = {
                    path: pathName
                }
                try {
                    await ApiService(requestObj).then(async () => {
                        return true
                    })
                } catch (error) {
                    console.log('error', error)
                }
            }, 300000)
        })

        return (
            <div className='loginBadge'>
                <div data-if='personImage'>
                    <img src={person.personImage} width='30px' height='30px' />
                </div>
                <div>{person.displayName}</div>
                <div>
                    <Icon iconName={'CaretSolidDown'} className='loginCaret' />
                </div>
            </div>
        )
    }

    const TemplateVCCFlyoutCommands = (props: MgtTemplateProps) => {
        return (
            <div className='loginFlyoutCommands'>
                <ButtonComponent
                    text='Sign out'
                    onClick={() => props.dataContext.handleSignOut()}
                    className='loginFlyoutButtons'
                />
            </div>
        )
    }

    const TemplateVCCFlyoutPerson = (props: MgtTemplateProps) => {
        const person = props.dataContext.personDetails

        return (
            <div className='loginFlyout'>
                <div className='loginFlyoutUser'>
                    <div>
                        <b>CDSID: </b>
                        {person.userPrincipalName.substring(
                            0,
                            person.userPrincipalName.indexOf('@')
                        )}
                    </div>
                </div>
                <div className='loginFlyoutInfo'>
                    <div>
                        <b>Roles: </b>
                        {roles && roles.join(', ')}
                    </div>
                </div>
                <div className='loginFlyoutInfo'>
                    <div>
                        <b>My Acknowledments : </b>
                        <Icon
                            style={{ color: 'rgb(0, 120, 212)', cursor: 'pointer' }}
                            iconName='entryView'
                            onClick={() => {
                                navigate(
                                    `/viewAcknowledgements?cdsid=${person.userPrincipalName.substring(
                                        0,
                                        person.userPrincipalName.indexOf('@')
                                    )}`
                                )
                            }}
                        />
                    </div>
                </div>
                <div className='loginFlyoutInfo'>
                    <div>
                        <b>Email Preferences : </b>
                        <Icon
                            style={{ color: 'rgb(0, 120, 212)', cursor: 'pointer' }}
                            iconName='edit'
                            onClick={() => {
                                navigate('/EmailPreferences')
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const resetSearchValue = () => {
        if (location.pathname !== '/dashboard/quickSearch') {
            setsearchVal('')
        }
        return false
    }

    useEffect(() => {
        resetSearchValue()
    }, [location.pathname])

    const searchHandler = (newValue: string) => {
        seterrorMessage('')
        setsearchVal(newValue)
        if (newValue.length > 0) {
            const searchErrorList = runValidation({
                value: newValue,
                minLength: 3
            })
            seterrorMessage(searchErrorList.join(''))
            if (searchErrorList.length === 0) {
                navigate('/dashboard/quickSearch', {
                    state: { search: newValue }
                })
            }
        } else {
            seterrorMessage('This field should have min 3 characters.')
        }
        setTimeout(() => {
            seterrorMessage('')
        }, 3000)
    }

    const changeHandler = (evt: any) => {
        if (evt !== undefined) {
            const searchErrorList = runValidation({
                value: evt.target.value
            })
            setsearchVal(evt.target.value)
            seterrorMessage(searchErrorList.join(''))
        }
    }

    let environmentName = ''
    switch (process.env?.REACT_APP_BUILD_ENV) {
        case 'development':
            environmentName = '- DEV'
            break

        case 'QA':
            environmentName = '- QA'
            break

        case 'PROD':
            environmentName = ' '
            break
        default:
            environmentName = ' '
            break
    }
    return (
        <div className='headerSection'>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
                <span style={itemStylesLeft}>
                    <Stack horizontal verticalAlign='center'>
                        <StackItem className='logoSibarContainer'>
                            <img src={logo} alt='volvo-logo' className='logoContainerBox' />
                        </StackItem>

                        <Stack.Item style={{ padding: '5px 5px' }}>
                            <Separator vertical styles={styles} />
                        </Stack.Item>

                        <Stack.Item style={{ padding: '5px 10px' }}> </Stack.Item>

                        <Stack.Item>
                            <Navbar />
                        </Stack.Item>

                        <Stack.Item style={{ padding: '5px 2px' }}> </Stack.Item>
                    </Stack>
                </span>
                <span style={itemStylesMiddle}>
                    <Stack horizontal verticalAlign='center'>
                        <StackItem className='appIconAndName'>
                            <img src={SCOTLogo} alt='ASR Logo' />
                            <h4>SCOT {environmentName}</h4>
                        </StackItem>
                    </Stack>
                </span>

                <span style={itemStylesRight}>
                    <Stack horizontal verticalAlign='center' className='headerNavList'>
                        <Stack.Item>
                            <div className='headerSearch'>
                                <SearchBox
                                    placeholder='Esow Id or name'
                                    onSearch={searchHandler}
                                    onChange={changeHandler}
                                    showIcon={false}
                                    value={searchVal}
                                    onClear={() => setsearchVal('')}
                                    onEscape={() => setsearchVal('')}
                                />
                                {errorMessage.length > 0 && (
                                    <div className='searchValidationErrorMsg'>{errorMessage}</div>
                                )}
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            {roles &&
                                (roles.includes(ROLES.ADMIN) ||
                                    roles.includes(ROLES.SUPERADMIN)) && (
                                    <DropdownNavComponent
                                        iconName='Settings'
                                        menuItems={settingsMenuItems}
                                    />
                                )}
                        </Stack.Item>
                        <Stack.Item>
                            <Login loginView='compact' className='loginBadgeContainer'>
                                <TemplateVCCLoggedIn template='signed-in-button-content'></TemplateVCCLoggedIn>
                                <TemplateVCCFlyoutCommands template='flyout-commands'></TemplateVCCFlyoutCommands>
                                <TemplateVCCFlyoutPerson template='flyout-person-details'></TemplateVCCFlyoutPerson>
                            </Login>
                        </Stack.Item>
                    </Stack>
                </span>
            </Stack>
        </div>
    )
}

export default Header
