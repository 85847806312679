import {
    AccountInfo,
    InteractionRequiredAuthError,
    IPublicClientApplication
} from '@azure/msal-browser'
import axios from 'axios'
import scotConfig from '../config'
import { loginRequest } from './AuthConfig'

export function RequestAccessToken(instance: IPublicClientApplication) {
    const account = instance.getActiveAccount()
    const accessTokenRequest: any = {
        ...loginRequest,
        account: account
    }

    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: { accessToken: any; expiresOn: moment.MomentInput }) => {
            const accessToken = accessTokenResponse.accessToken
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
            return accessToken
        })
        .catch(async (error: any) => {
            if (error instanceof InteractionRequiredAuthError) {
                return await instance.acquireTokenPopup(accessTokenRequest).catch((error: any) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        return instance.acquireTokenRedirect(accessTokenRequest)
                    }
                })
            }
            console.log(error)
        })
}

export function signOutClickHandler(instance: IPublicClientApplication, account: AccountInfo) {
    const logoutRequest = {
        account: account,
        postLogoutRedirectUri: scotConfig.REACT_APP_MSAL_LOGIN_REDIRECT_URI
    }
    instance.logoutRedirect(logoutRequest)
}
