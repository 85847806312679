import React, { useEffect } from 'react'
import { StyleProvider, ThemePicker } from 'vcc-ui'
import {
    AuthenticatedTemplate,
    MsalAuthenticationTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal
} from '@azure/msal-react'
import { ITextProps, Text } from '@fluentui/react'
import 'react-quill/dist/quill.snow.css'
import './App.scss'
import { loginRequest, storageRequest } from './services/AuthConfig'
import { InteractionType } from '@azure/msal-browser'
import LoadingComponent from './components/atoms/LoadingComponent'
import AuthFailure from './components/templates/AuthFailure/AuthFailure'
import MainAppContainer from './MainAppContainer'

const AutoRedirectToLogin = () => {
    const { instance } = useMsal()
    const handleLogin = async () => {
        await instance.loginRedirect(storageRequest).catch((e) => {
            console.log(e)
        })
    }
    useEffect(() => {
        handleLogin()
    }, [])

    return (
        <div className='layoutContainer'>
            <div className='AuthFailure'>
                <Text variant={'xxLarge' as ITextProps['variant']} nowrap block>
                    Authenticating....
                </Text>
            </div>
        </div>
    )
}
function App() {
    const isAuthenticated = useIsAuthenticated()

    return (
        <div className='App'>
            <StyleProvider>
                <ThemePicker variant='light'>
                    {!isAuthenticated ? (
                        <UnauthenticatedTemplate>
                            <AutoRedirectToLogin />
                        </UnauthenticatedTemplate>
                    ) : (
                        <AuthenticatedTemplate>
                            <MsalAuthenticationTemplate
                                interactionType={InteractionType.Redirect}
                                authenticationRequest={loginRequest}
                                errorComponent={AuthFailure}
                                loadingComponent={LoadingComponent}
                            >
                                <MainAppContainer />
                            </MsalAuthenticationTemplate>
                        </AuthenticatedTemplate>
                    )}
                </ThemePicker>
            </StyleProvider>
        </div>
    )
}

export default App
