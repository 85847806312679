import {
    MAX_LENGTH,
    MAX_VALUE,
    MIN_LENGTH,
    MIN_VALUE,
    REQUIRED_FIELD,
    SPECIAL_CHARS,
    VALIDATION_FOR_GAP,
    XSSATTACHCHECK
} from './ERRORMESSAGES'

interface validationProps {
    value: string
    regex?: any
    isRequired?: boolean
    minLength?: number
    maxLength?: number
    minVal?: number
    maxVal?: number
    gapOf?: number
    errorMessage?: string
}
export const runValidation = (props: validationProps) => {
    const {
        value,
        regex,
        isRequired,
        minLength,
        maxLength,
        minVal,
        maxVal,
        gapOf,
        errorMessage = SPECIAL_CHARS
    } = props
    const validate = new RegExp(regex)
    const errors = []

    if (value?.length > 1) {
        if (value.includes('<script>') || value.includes('</script>')) {
            errors.push(XSSATTACHCHECK)
        }
    }

    if (isRequired) {
        if (isRequired && value?.length < 1) {
            errors.push(REQUIRED_FIELD)
        }
    }

    if (minLength) {
        if (minLength && value?.length !== 0 && value?.length < minLength) {
            errors.push(MIN_LENGTH(minLength))
        }
    }

    if (maxLength) {
        if (maxLength && value?.length !== 0 && value?.length > maxLength) {
            errors.push(MAX_LENGTH(maxLength))
        }
    }

    if (minVal) {
        if (minVal && value?.length !== 0 && minVal > parseInt(value)) {
            errors.push(MIN_VALUE(minVal))
        }
    }

    if (maxVal) {
        if (maxVal && value?.length !== 0 && maxVal < parseInt(value)) {
            errors.push(MAX_VALUE(maxVal))
        }
    }
    if (gapOf) {
        if (gapOf && value?.length !== 0 && parseInt(value) % gapOf !== 0) {
            errors.push(VALIDATION_FOR_GAP(gapOf))
        }
    }

    if (regex) {
        const val = value?.toString()
        if (val) {
            if (!validate.test(val)) {
                errors.push(errorMessage || SPECIAL_CHARS)
            }
        }
    }

    return errors
}

export const ALLOWED_IMAGE_TYPES = ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'svg', 'tiff', 'tif']
export const ALLOWED_VIDEO_TYPES = ['mp4', 'mov']
export const ALLOWED_DOC_TYPES = [
    'csv',
    'email',
    'bmp',
    'gif',
    'jpeg',
    'jpg',
    'png',
    'svg',
    'tiff',
    'tif',
    'ppt',
    'pptx',
    'xls',
    'ods ',
    'xlsx',
    'xlsm',
    'doc',
    'docx',
    'odt',
    'pdf',
    'rtf',
    'txt',
    'wpd',
    '7z',
    'rar',
    'zip',
    'msk',
    'eps',
    'msg',
    'mp4',
    'mov'
]
