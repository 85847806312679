import { mergeStyleSets } from '@fluentui/react/lib/Styling'

export const styles = mergeStyleSets({
    root: {
        display: 'flex'
    },
    required: {
        color: 'red'
    },
    labelStyle: {
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        color: '#201f1e'
    },
    asterix: {
        padding: '0px 4px'
    }
})
