import React from 'react'
import ReactDOM from 'react-dom/client'

import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import App from './App'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { msalConfig } from './services/AuthConfig'
import { Providers } from '@microsoft/mgt-react'

initializeIcons()

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.enableAccountStorageEvents()

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts()
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0])
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult
            const account = payload.account
            msalInstance.setActiveAccount(account)
        }
    })
    Providers.globalProvider = new Msal2Provider({ publicClientApplication: msalInstance })
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </React.StrictMode>
    )
})
