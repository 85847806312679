import * as React from 'react'
import { DefaultButton, Stack } from '@fluentui/react'

const stackTokens = { childrenGap: 10 }
interface ButtonProps {
    text: string
    checked?: boolean
    disabled?: boolean
    buttonType?: 'primary' | 'default'
    className?: string
    onClick?: any
    icon?: any
    style?: any
    ariaDescribedBy?: any
}

const ButtonComponent: React.FunctionComponent<ButtonProps> = (props) => {
    const {
        text,
        checked,
        disabled = false,
        buttonType = 'default',
        className,
        onClick,
        icon,
        style,
        ariaDescribedBy
    } = props

    return (
        <Stack horizontal tokens={stackTokens}>
            {buttonType === 'default' ? (
                <DefaultButton
                    text={text}
                    checked={checked}
                    disabled={disabled}
                    className={className}
                    onClick={onClick}
                    iconProps={icon}
                    style={style}
                    aria-describedby={ariaDescribedBy}
                />
            ) : (
                <>
                    <DefaultButton
                        primary
                        text={text}
                        checked={checked}
                        disabled={disabled}
                        className={className}
                        onClick={onClick}
                        iconProps={icon}
                        style={style}
                        aria-describedby={ariaDescribedBy}
                    />
                </>
            )}
        </Stack>
    )
}

export default ButtonComponent
