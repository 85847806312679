import React, { useCallback, useEffect, useState } from 'react'
import { IContextualMenuItem, IStackTokens, Stack } from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from '../../../routes'
import ComdBarButton from '../CommandBarButton'
import DropdownNavComponent from '../DropdownNav'
import AboutModal from 'components/molecules/About/AboutModal'

const outerStackTokens: IStackTokens = { childrenGap: 5 }

export const Navbar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [aboutPanelOpen, setAboutPanelOpen] = useState(false)

    const navigateToRoute = (item: any) => {
        item.path.substring(0, 4) === 'http'
            ? window.open(item.path, '_blank')
            : item.path.substring(0, 7) === '<About>'
            ? setAboutPanelOpen(true)
            : navigate(item.path, { state: { from: pathname } })
    }

    const [helpMenuItems, setHelpMenuItems]: any = useState([])
    const headerNavRoutesHelp = routes.filter((item) => item?.navPlacement === 'help')
    const updateNavHeadHelp = useCallback(() => {
        const navRoutes: IContextualMenuItem[] = []
        headerNavRoutesHelp.forEach((item) => {
            const newMenuItem: any = {
                key: item.path,
                text: item.text,
                onClick: () => navigateToRoute(item)
            }
            navRoutes.push(newMenuItem)
        })
        setHelpMenuItems(navRoutes)
        return navRoutes
    }, [])

    useEffect(() => {
        updateNavHeadHelp()
    }, [])

    return (
        <>
            <Stack
                horizontal
                tokens={outerStackTokens}
                className='rowPadding'
                data-cy='headerNavbar'
            >
                {routes.map((item: any) => {
                    return item.mainNav && item?.navPlacement !== 'help' ? (
                        <Stack.Item key={item.key}>
                            <ComdBarButton
                                label={item.text}
                                iconProps={item.icon && { iconName: item.icon }}
                                onClick={() => navigateToRoute(item)}
                                className={`navBarItems ${
                                    item?.path === pathname && `navBarItemActive`
                                }`}
                                title={item.pageTitle}
                            />
                        </Stack.Item>
                    ) : (
                        ''
                    )
                })}
                <Stack.Item>
                    <DropdownNavComponent menuItems={helpMenuItems} menuText='Help' />
                </Stack.Item>
            </Stack>
            <AboutModal aboutPanelOpen={aboutPanelOpen} setAboutPanelOpen={setAboutPanelOpen} />
        </>
    )
}
