import { useMsal } from '@azure/msal-react'
import { ITextProps, mergeStyles, Separator, Text, Icon } from '@fluentui/react'
import React from 'react'
import { storageRequest } from '../../../services/AuthConfig'

const iconClass = mergeStyles({
    fontSize: 50,
    height: 70,
    width: 70
})
const AuthFailure: React.FC<any> = ({ error }) => {
    const { instance } = useMsal()
    const handleLogin = async () => {
        await instance.loginRedirect(storageRequest).catch((e) => {
            console.log(e)
        })
    }
    return (
        <div className='layoutContainer'>
            <div className='AuthFailure'>
                <div className='appIconAndName'>
                    <Icon iconName='Lock' data-action='403' className={iconClass} />
                </div>

                <Text variant={'xxLarge' as ITextProps['variant']} nowrap block>
                    Authentication Failure
                </Text>
                <Separator alignContent='center' />
                <Text variant={'mediumPlus' as ITextProps['variant']} nowrap block>
                    {error}
                </Text>
                <p>
                    <button onClick={() => handleLogin()}>SignIn</button>
                </p>
            </div>
        </div>
    )
}

export default AuthFailure
