import { MessageBarType } from '@fluentui/react'
import React from 'react'
import { NotificationProps } from '../interface/globalTypes'

interface NotificationContextProps {
    notificationAlert: NotificationProps
    setNotifications: any
    loading?: boolean
    setLoading?: any
}

export const NotificationContext = React.createContext<Partial<NotificationContextProps>>({
    notificationAlert: {
        message: '',
        type: MessageBarType.info
    }
})
