import * as React from 'react'
import { useId } from '@fluentui/react-hooks'
import { Modal, IIconProps } from '@fluentui/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { contentStyles, iconButtonStyles } from './SimpleModalStyles'
import SimpleLabel from '../Label'
const cancelIcon: IIconProps = { iconName: 'Cancel' }

interface SimpleModalProps {
    title: string
    isModalOpen: boolean
    onDismiss: any
    children: React.ReactNode
    minWidth?: string
    width?: string
    height?: string
}
const SimpleModal = ({
    title,
    isModalOpen,
    onDismiss,
    children,
    minWidth = '37.5rem',
    // width = 'auto',
    width,
    height = 'auto'
}: SimpleModalProps) => {
    const titleId = useId(title)
    const contStyles: any = {
        ...contentStyles,
        container: { minWidth: minWidth, width: width, height: height, paddingBottom: '1rem' }
    }

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={isModalOpen}
            onDismiss={onDismiss}
            isModeless={false}
            containerClassName={contStyles.container}
        >
            <div className={contentStyles.header}>
                <SimpleLabel labelName={title} variant='large' />
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel='Close popup modal'
                    onClick={onDismiss}
                />
            </div>

            <div className={contStyles.body}>{children}</div>
        </Modal>
    )
}

export default SimpleModal
