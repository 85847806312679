import * as React from 'react'
import { Stack } from '@fluentui/react/lib/Stack'
import {
    stackItemStyles,
    outerStackTokens,
    innerStackTokens,
    itemContainerPadding
} from './PageBannerStyles'
import { IStyle } from '@fluentui/react'
import scotConfig from '../../../config'

interface IPageBaneer {
    heading: string
    subHeading?: string
}
export const PageBannerTop: React.FunctionComponent<IPageBaneer> = ({ heading }: IPageBaneer) => {
    const envColorSetup = () => {
        let pageHeaderStyle: React.CSSProperties
        let backgroundColor: IStyle
        switch (scotConfig?.REACT_APP_BUILD_ENV) {
            case 'development':
                backgroundColor = {
                    background: '#141414',
                    color: '#FFF'
                }
                pageHeaderStyle = {
                    color: '#FFF',
                    fontSize: '16px',
                    fontWeight: '600',
                    textTransform: 'uppercase'
                }
                break

            case 'QA':
                backgroundColor = {
                    background: '#141414',
                    color: '#FFF'
                }
                pageHeaderStyle = {
                    color: '#FFF',
                    fontSize: '16px',
                    fontWeight: '600',
                    textTransform: 'uppercase'
                }
                break

            default:
                backgroundColor = {
                    background: '#141414',
                    color: '#FFF'
                }
                pageHeaderStyle = {
                    color: '#FFF',
                    fontSize: '16px',
                    fontWeight: '600',
                    textTransform: 'uppercase'
                }
                break
        }
        return { backgroundColor, pageHeaderStyle }
    }

    return (
        <Stack
            enableScopedSelectors
            tokens={outerStackTokens}
            styles={{
                root: envColorSetup().backgroundColor
            }}
            className='pageBannerTop'
        >
            <Stack
                enableScopedSelectors
                styles={stackItemStyles}
                tokens={innerStackTokens}
                horizontalAlign='start'
            >
                <Stack.Item style={itemContainerPadding}>
                    <div style={envColorSetup().pageHeaderStyle}>{heading}</div>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
