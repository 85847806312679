import axios from 'axios'

axios.create()
interface ApiServiceProps {
    path: string
    method?: string
    payload?: any
    type?: any
    timeout?: number
    params?: any
}
export const ApiService = (props: ApiServiceProps) => {
    const { path, method = 'get', payload = null, type = null, timeout = 0, params = null } = props
    return axios({
        method: method,
        url: `${path}`,
        data: payload,
        responseType: type,
        timeout: timeout,
        params: params
    })
}

const ApiExports = {
    ApiService
}

export default ApiExports
