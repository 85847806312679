export const REQUIRED_FIELD = 'Field is required. '
export const ONLY_POSITIVE_NUMBERS = 'Only positive numbers are allowed. '
export const ONLY_NUMBERS = 'Only numbers are allowed. '
export const ALREADY_EXISTED_DROPDOWN_OPTION = 'This Dropdown option already exists in the system. '
export const XSSATTACHCHECK = 'Script Tags are not allowed.'
export const SPECIAL_CHARS = 'Special characters are not allowed. '
export const OTHER_OPTION_COE_MESSAGE = 'Allowed inputs are (a-z A-Z 0-9 öÖäÅåé _ . -) '
export const MIN_LENGTH = (minLength: number) => {
    return 'This field should have min ' + minLength + ' characters. '
}

export const MAX_LENGTH = (maxLength: number) => {
    return 'This field exceeds its max allowed characters limit of ' + maxLength + ' characters. '
}

export const MIN_VALUE = (minVal: number) => {
    return 'This field should have minimum value ' + minVal
}

export const MAX_VALUE = (maxVal: number) => {
    return 'This field should have maximum value ' + maxVal
}

export const VALIDATION_FOR_GAP = (gap: number) => {
    return `Value should be divisible by ${gap}`
}
export const DELETE_CONFIRM = 'Are you Sure you want to remove this?'
export const SENT_QUESTIONNAIRE = 'Are you Sure you want to send these questionnaires?'

export const LOG_MESSAGES = {
    FORBIDDEN_STATUS: '403 - Forbidden',
    FORBIDDEN_STATUS_MESSAGE: 'You do not have access to this page/application.',
    FORBIDDEN_PERMISSION: 'You do not have access to any of the permissions.',
    GET_ERROR_MATRIX_PERMISSION: 'Failed to load your permissions.',
    GET_ROLES_MATRIX_ERROR_MSG: 'Failed to authorize access.',
    GET_ERROR_MSG: 'Failed to load data.',
    UPLOAD_SUCCESS: 'File uploaded successfully.',
    UPLOAD_ERROR: 'Failed to upload file',
    DOWNLOAD_SUCCESS: 'File downloaded.',
    DOWNLOAD_ERROR: 'File Download Failed',
    SUBMIT_REQUIRED_FIELDS_ERROR:
        'Please fill all the required fields before submitting base coding.',
    COPY_TO_CLIPBOARD: 'Copied to Clipboard.',
    ADD_SUCCESS_MSG: 'Added successfully.',
    ADD_ERROR_MSG: 'Failed to add.',
    SAVE_SUCCESS_MSG: 'Saved successfully.',
    SAVE_ERROR_MSG: 'failed to Save.',
    PUBLISH_SUCCESS_MSG: 'Published successfully.',
    PUBLISH_ERROR_MSG: 'failed to publish.',
    DELETE_SUCCESS_MSG: 'Deleted successfully.',
    DELETE_ERROR_MSG: 'Failed to delete.',
    UPDATE_SUCCESS_MSG: 'Data updated successfully.',
    UPDATE_ERROR_MSG: 'Failed to update.',
    NOTES_GET_ERROR_MSG: 'Failed to retrieve Notes.',
    NOTES_ADD_ERROR_MSG: 'Failed to add Notes.',
    CASE_LOGS_GET_ERROR_MSG: 'Failed to retrieve logs.',
    FILE_SIZE_ERROR_MSG: 'Failed: Max file size allowed is 15MB.',
    MIN_ONEROW_REQUIRED: 'Atleast one row of data is required.',
    CONFLICT_REQUIRED: 'Conflict situation have empty field values',
    SOMETHING_WENT_WRONG: 'Something went wrong.',
    API_NOT_IMPLEMENTED: 'API implementation is not ready, changes may not reflect.',
    NO_IMAGE_FOUND: 'No images found.',
    NO_VIDEO_FOUND: 'No video found.',
    NO_DATA_FOUND: 'No data available.',
    NO_SCANNEDDOC_FOUND: 'No scanned documents found.',
    SUBMIT_SUCCESS_MSG: 'Base coding submitted successfully.',
    REVERT_SUCCESS_MSG: 'Base coding submit state reverted successfully.',
    SAVE_BASECODING_ERROR_MSG: 'Failed to save base coding.',
    SUBMIT_ERROR_MSG: 'Failed to submit base coding.',
    REVERT_ERROR_MSG: 'Failed to revert base coding submission.',
    CONFLICT_SITUATION_GET_ERROR: 'Failed to load conflict situation data.',
    CONFLICT_CODE_GET_ERROR: 'Conflict code is not available for this combination.',
    CONFLICT_CATEGORY_GET_ERROR: 'Failed to load conflict category data.',
    ALLOWED_IMAGE_ERROR: 'Your uploaded image format/type is not supported.',
    ALLOWED_VIDEO_ERROR: 'Your uploaded video format/type is not supported.',
    ALLOWED_FILE_ERROR: 'Your uploaded file format/type is not supported.',
    GET_ERROR_CATEGORY: 'Error while category data.',
    NOT_AUTHORIZED: 'Access to the requested resource is forbidden.',
    SUPPLIER_SUCCESS_MSG: 'Supplier ESOW has been created successfully',
    SUPPLIER_DETAILS_SUCCESS_MSG: 'Supplier details has been added/updated successfully',
    SUPPLIER_ERROR_MSG: 'Failed to cretate supplier ESOW',
    SUPPLIER_DETAILS_ERROR_MSG: 'Failed to add/update supplier details',
    SEARCH_SUCCESS: 'Search results fetched successfully',
    ADDITIONAL_SUPPLIER_ADD_ERROR_MSG: 'Failed to additional supplier',
    FAILED_TO_ZIP_NO_FILES: 'Failed to zip, no files found',
    COPY_NEWROUND_SUCCESS_MSG: 'Copied to new round successfully.'
}

export const FETCH_LONG_HOLD = 'Fetching information this might take sometime.'
export const SAVE_LONG_HOLD = 'Saving information this might take sometime.'
export const DO_NOT_RELOAD = 'Do not reload or refresh the page.'
export const SET_AS_FAVOURITE = 'Favourite Status changed successfully'
export const ERROR_FAVOURITE = 'Failed to change Favourite Status'
export const SEARCH_NO_CARCASEID =
    'First column needs to be a carcase id to be able to save this as a sample'
export const LOG_WARNING = {
    SNAPSHOT_REQUIRED:
        'You must save forecast and verification status. Then you can add a key challenge.',

    PROJECT_ATTRIBUTE_SELECTION_REQUIRED:
        'You need to select Project and Attribute to be able to add key challenges.'
}
