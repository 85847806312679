const scotConfig = {
    REACT_APP_WEB_API_BASE_URI: process.env.REACT_APP_WEB_API_BASE_URI as string,
    REACT_APP_MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    REACT_APP_MSAL_CLIENT_SCOPE: process.env.REACT_APP_MSAL_CLIENT_SCOPE as string,
    REACT_APP_MSAL_TENANT_AUTHORITY_URI: process.env.REACT_APP_MSAL_TENANT_AUTHORITY_URI as string,
    REACT_APP_MSAL_CACHE_LOCATION: process.env.REACT_APP_MSAL_CACHE_LOCATION as string,
    REACT_APP_MSAL_LOGIN_REDIRECT_URI: window.origin,
    REACT_APP_BUILD_ID: '',
    REACT_APP_BUILD_ENV: process.env.REACT_APP_BUILD_ENV as string
}

export default scotConfig
