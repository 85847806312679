import React from 'react'
import { Separator, Text, ITextProps, Icon } from '@fluentui/react'
import { mergeStyles } from '@fluentui/react/lib/Styling'

const iconClass = mergeStyles({
    fontSize: 50,
    height: 70,
    width: 70
})

type props = {
    status?: string
    message: string
}
const UnAuthorized: React.FC<props> = ({ status, message }) => {
    return (
        <div className='layoutContainer'>
            <div className='AuthFailure'>
                <div className='appIconAndName'>
                    <Icon iconName='Lock' data-action='403' className={iconClass} />
                </div>

                <Text variant={'xxLarge' as ITextProps['variant']} nowrap block>
                    {status}
                </Text>
                <Separator alignContent='center' />
                <Text variant={'mediumPlus' as ITextProps['variant']} nowrap block>
                    {message}
                </Text>
            </div>
        </div>
    )
}

export default UnAuthorized
