import React from 'react'
import { styles } from './LabelStyles'

export interface LabelProps {
    labelName: string
    className?: string
    isRequired?: boolean
    variant?: 'small' | 'medium' | 'large'
    style?: any
}
const Label: React.FC<LabelProps> = (props) => {
    const { root, labelStyle, required, asterix } = styles
    const { labelName, className, isRequired = false, variant = 'small', style } = props

    let fontSize
    switch (variant) {
        case 'small':
            fontSize = '12px'
            break

        case 'medium':
            fontSize = '14px'
            break

        case 'large':
            fontSize = '16px'
            break
        default:
            fontSize = '12px'
            break
    }

    return (
        <div className={root}>
            <span className={`${labelStyle} ${className}`} style={{ fontSize, ...style }}>
                {labelName}
                {isRequired && <span className={`${required} ${className} ${asterix}`}>*</span>}
            </span>
        </div>
    )
}
export default Label
