import React from 'react'
import { CommandBarButton, Stack, IIconProps } from '@fluentui/react'

const stackTokens = { childrenGap: 10 }

interface CmdButtonProps {
    label?: string
    checked?: boolean
    disabled?: boolean
    onClick?: () => any
    className?: string
    iconProps?: IIconProps
    title?: string
    style?: any
}

const ComdBarButton: React.FunctionComponent<CmdButtonProps> = (props) => {
    const { label, checked, disabled = false, className, onClick, title, iconProps, style } = props

    return (
        <Stack tokens={stackTokens}>
            <CommandBarButton
                iconProps={iconProps}
                text={label}
                disabled={disabled}
                checked={checked}
                className={className}
                onClick={onClick}
                title={title}
                style={style}
            />
        </Stack>
    )
}

export default ComdBarButton
