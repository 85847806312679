import React, { ReactElement, useState } from 'react'
import Modal from '../../atoms/Modal'
import preval from 'preval.macro'
import ChangeLog from 'components/templates/DefaultLayout/ChangeLog'

interface IAboutProps {
    aboutPanelOpen: any
    setAboutPanelOpen: any
}
const AboutModal: React.FC<IAboutProps> = (props): ReactElement => {
    const { aboutPanelOpen, setAboutPanelOpen } = props
    const [modalState, setModalState] = useState(false)

    const onDismiss = () => {
        setAboutPanelOpen(false)
    }

    return (
        <>
            <Modal
                isModalOpen={aboutPanelOpen}
                onDismiss={onDismiss}
                title='About'
                minWidth='40rem'
            >
                <div className='aboutContainer'>
                    <div>
                        <b>Last Updated:</b> {preval`module.exports = new Date().toLocaleString();`}
                    </div>
                    <div>
                        <b>Environment:</b> {process.env?.REACT_APP_BUILD_ENV || 'development'}
                    </div>
                    <div>
                        <b>Build Id:</b> {process.env?.REACT_APP_BUILD_ID || '-'}
                    </div>
                    <div>
                        <b>Release:</b>{' '}
                        <a href='#' onClick={() => setModalState(true)}>
                            {' '}
                            Changelog
                        </a>
                    </div>
                </div>
            </Modal>
            <Modal
                isModalOpen={modalState}
                onDismiss={() => setModalState(false)}
                title={'Release ChangeLog'}
            >
                <div className='modalContainerBigSize'>
                    <ChangeLog />
                </div>
            </Modal>
        </>
    )
}

export default AboutModal
