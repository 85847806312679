import { getTheme, mergeStyleSets } from '@fluentui/react'

const theme = getTheme()
export const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: '85%'
    },
    header: [
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 12px 14px 24px'
        }
    ],
    body: {
        padding: '0px 10px',
        maxHeight: 'calc(100vh - 130px)',
        overflowY: 'auto'
    }
})

export const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
}
