import { IStackStyles, DefaultPalette, IStackItemStyles, IStackTokens } from '@fluentui/react'
import { CSSProperties } from 'react'

export const stackStyles: IStackStyles = {
    root: {
        background: '#161618',
        color: DefaultPalette.white
    }
}

export const stackItemStyles: IStackItemStyles = {
    root: {
        color: DefaultPalette.white,
        display: 'flex'
    }
}

export const outerStackTokens: IStackTokens = { childrenGap: 10 }
export const innerStackTokens: IStackTokens = {
    childrenGap: 5
}

export const itemContainerPadding: CSSProperties = { margin: '10px 80px' }
export const pageHeaderStyle: CSSProperties = {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
}
export const pageSubHeaderStyle: CSSProperties = { color: '#fff', fontSize: 'large' }
