import React, { FC, ReactElement, useEffect } from 'react'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { NotificationContext } from '../../../context/NotificationContext'

type props = {
    resetNotification?: () => void
}

const NotificationAlert: FC<props> = (): ReactElement => {
    const { notificationAlert, setNotifications } = React.useContext(NotificationContext)

    useEffect(() => {
        if (notificationAlert !== undefined && notificationAlert?.message.length > 0) {
            setTimeout(() => {
                setNotifications('', MessageBarType.info)
            }, 5000)
        }
    }, [notificationAlert?.message])

    return (
        <div
            className={'sct-notification'}
            style={{
                display:
                    notificationAlert !== undefined && notificationAlert?.message.length > 0
                        ? ''
                        : 'none'
            }}
        >
            <MessageBar delayedRender={false} messageBarType={notificationAlert?.type}>
                <div className='notification-message'>{notificationAlert?.message}</div>
            </MessageBar>
        </div>
    )
}

export default NotificationAlert
