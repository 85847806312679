import { useMsal, useAccount } from '@azure/msal-react'
import UnAuthorized from './components/templates/AuthFailure/UnAuthorized'
import DefaultLayout from './components/templates/DefaultLayout'
import React, { useEffect } from 'react'
import { LOG_MESSAGES } from './utils/ERRORMESSAGES'
import axios from 'axios'
import { RequestAccessToken } from 'services/AuthUtils'

function MainAppContainer() {
    const { accounts, instance } = useMsal()
    const account = useAccount(accounts[0] || {})
    const roles = account?.idTokenClaims?.roles

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(
            async (config: any) => {
                await RequestAccessToken(instance)
                return config
            },
            async (error) => {
                return Promise.reject(error)
            }
        )

        return () => {
            axios.interceptors.request.eject(interceptor)
        }
    })

    return (
        <>
            {roles && roles?.length > 0 ? (
                <>
                    <DefaultLayout />
                </>
            ) : (
                <UnAuthorized
                    status={LOG_MESSAGES.FORBIDDEN_STATUS}
                    message={LOG_MESSAGES.FORBIDDEN_STATUS_MESSAGE}
                />
            )}
        </>
    )
}
export default MainAppContainer
